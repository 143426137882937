/*--------------------------------------------------------------------------
  Constants! In CSS! The future is here!
 --------------------------------------------------------------------------*/

:root {
  --correct-bg: forestgreen;
  --correct-fg: #eee;
  --correct-border: forestgreen;
  --move-bg: goldenrod;
  --move-fg: #eee;
  --move-border: goldenrod;
  --incorrect-bg: #666;
  --incorrect-fg: #eee;
  --incorrect-border: #666;
  --unknown-bg: #ddd;
  --unknown-fg: #333;
  --unknown-border: #aaa;
  --active-color: darkslateblue;
}

/*--------------------------------------------------------------------------
  Top-level styles for the site.
 --------------------------------------------------------------------------*/

body {
  background-color: #f0f0f0;
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", sans-serif;
}

div {
  margin: 0;
  padding: 0;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.title,
.subtitle {
  text-align: center;
}

.subtitle {
  font-weight: 400;
  color: #333;
  padding-top: 0;
  margin-top: -2em;
  margin-bottom: 3em;
}

@media only screen and (min-width: 800px) {
  .container {
    width: 800px;
  }
}

/*--------------------------------------------------------------------------
  Clue Colors
 --------------------------------------------------------------------------*/

.correct {
  background-color: var(--correct-bg) !important;
  color: var(--correct-fg) !important;
  border: 2px solid var(--correct-border) !important;
}

.move {
  background-color: var(--move-bg) !important;
  color: var(--move-fg) !important;
  border: 2px solid var(--move-border) !important;
}

.incorrect {
  background-color: var(--incorrect-bg) !important;
  color: var(--incorrect-fg) !important;
  border: 2px solid var(--incorrect-border) !important;
}

.unknown {
  background-color: var(--unknown-bg) !important;
  color: var(--unknown-fg) !important;
  border: 2px solid var(--unknown-border) !important;
}

/*--------------------------------------------------------------------------
  Keyboard fun!
 --------------------------------------------------------------------------*/

.keyboard {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0 0.5em;
}

.keyboard .row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  padding: 0;
  margin: 0 0 0.25em;
}

.keyboard .key {
  display: flex;
  padding: 0 0.125em;
  margin: 0 0.25em 0 0;
  border-width: 1px !important;
  flex: 1;
  font-weight: 500;
  font-size: 1.25em;
  line-height: 2em;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border-radius: 0.1667em;
  cursor: pointer;
  outline: none;
}

.keyboard .key.wide {
  flex: 2;
}

.keyboard .key:last-child {
    margin-right: 0;
}

@media only screen and (min-width: 800px) {
  .keyboard {
    width: 66%;
    margin: 0 auto;
  }


  .keyboard .key {
    flex: 1;
    font-weight: 400;
    font-size: 1.25em;
    line-height: 2em;
  }
}

/*--------------------------------------------------------------------------
  Solution grid
 --------------------------------------------------------------------------*/

.grid {
  display: flex;
  flex-direction: column;
  font-weight: 900;
  width: 80%;
  margin: 0 auto;
}

.grid .row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  margin-bottom: 0.2em;
}

.grid .row:last-child {
  margin-bottom: 0;
}

.grid .cell {
  margin-right: 0.2em;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  font-size: 1.5em;
  font-weight: 900;
  line-height: 2em;
}

.grid .cell:last-child {
  margin-right: 0;
}

@media only screen and (min-width: 800px) {
  .grid {
    width: 50%;
  }

  .grid .cell {
    font-size: 2em;
    font-weight: 900;
    line-height: 2em;
  }
}

/*--------------------------------------------------------------------------
  Clue Cats! (Nortofni42)
 --------------------------------------------------------------------------*/

.grid .correct,
.grid .move,
.grid .incorrect {
  position: relative;
}

.grid .correct::after,
.grid .move::after,
.grid .incorrect::after {
  font-size: 1.3333em;
  position: absolute;
  top: -0.625em;
  right: -0.325em;
  transform: rotate(-45deg) scale(0);
  z-index: 10;
  opacity: 0;
}

.grid .correct.final::after,
.grid .move.final::after,
.grid .incorrect.final::after {
  transform: rotate(45deg) scale(1);
  opacity: 0.95;
  transition: opacity 0.333s ease-in-out, transform 0.333s ease-in-out;
}

.grid .correct::after {
  content: "😻";
}

.grid .move::after {
  content: "😸";
}

.grid .incorrect::after {
  content: "";
}

.hidden {
  display: none;
}
