:root {
  --correct-bg: forestgreen;
  --correct-fg: #eee;
  --correct-border: forestgreen;
  --move-bg: goldenrod;
  --move-fg: #eee;
  --move-border: goldenrod;
  --incorrect-bg: #666;
  --incorrect-fg: #eee;
  --incorrect-border: #666;
  --unknown-bg: #ddd;
  --unknown-fg: #333;
  --unknown-border: #aaa;
  --active-color: darkslateblue;
}

body {
  background-color: #f0f0f0;
  margin: 0;
  padding: 0;
  font-family: Helvetica Neue, sans-serif;
}

div {
  margin: 0;
  padding: 0;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.title, .subtitle {
  text-align: center;
}

.subtitle {
  color: #333;
  margin-top: -2em;
  margin-bottom: 3em;
  padding-top: 0;
  font-weight: 400;
}

@media only screen and (min-width: 800px) {
  .container {
    width: 800px;
  }
}

.correct {
  background-color: var(--correct-bg) !important;
  color: var(--correct-fg) !important;
  border: 2px solid var(--correct-border) !important;
}

.move {
  background-color: var(--move-bg) !important;
  color: var(--move-fg) !important;
  border: 2px solid var(--move-border) !important;
}

.incorrect {
  background-color: var(--incorrect-bg) !important;
  color: var(--incorrect-fg) !important;
  border: 2px solid var(--incorrect-border) !important;
}

.unknown {
  background-color: var(--unknown-bg) !important;
  color: var(--unknown-fg) !important;
  border: 2px solid var(--unknown-border) !important;
}

.keyboard {
  flex-direction: column;
  margin: 0 .5em;
  padding: 0;
  display: flex;
}

.keyboard .row {
  flex-direction: row;
  justify-content: stretch;
  margin: 0 0 .25em;
  padding: 0;
  display: flex;
}

.keyboard .key {
  text-transform: uppercase;
  cursor: pointer;
  border-radius: .1667em;
  outline: none;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 0 .25em 0 0;
  padding: 0 .125em;
  font-size: 1.25em;
  font-weight: 500;
  line-height: 2em;
  display: flex;
  border-width: 1px !important;
}

.keyboard .key.wide {
  flex: 2;
}

.keyboard .key:last-child {
  margin-right: 0;
}

@media only screen and (min-width: 800px) {
  .keyboard {
    width: 66%;
    margin: 0 auto;
  }

  .keyboard .key {
    flex: 1;
    font-size: 1.25em;
    font-weight: 400;
    line-height: 2em;
  }
}

.grid {
  width: 80%;
  flex-direction: column;
  margin: 0 auto;
  font-weight: 900;
  display: flex;
}

.grid .row {
  flex-direction: row;
  justify-content: stretch;
  margin-bottom: .2em;
  display: flex;
}

.grid .row:last-child {
  margin-bottom: 0;
}

.grid .cell {
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-right: .2em;
  font-size: 1.5em;
  font-weight: 900;
  line-height: 2em;
  display: flex;
}

.grid .cell:last-child {
  margin-right: 0;
}

@media only screen and (min-width: 800px) {
  .grid {
    width: 50%;
  }

  .grid .cell {
    font-size: 2em;
    font-weight: 900;
    line-height: 2em;
  }
}

.grid .correct, .grid .move, .grid .incorrect {
  position: relative;
}

.grid .correct:after, .grid .move:after, .grid .incorrect:after {
  z-index: 10;
  opacity: 0;
  font-size: 1.3333em;
  position: absolute;
  top: -.625em;
  right: -.325em;
  transform: rotate(-45deg)scale(0);
}

.grid .correct.final:after, .grid .move.final:after, .grid .incorrect.final:after {
  opacity: .95;
  transition: opacity .333s ease-in-out, transform .333s ease-in-out;
  transform: rotate(45deg)scale(1);
}

.grid .correct:after {
  content: "😻";
}

.grid .move:after {
  content: "😸";
}

.grid .incorrect:after {
  content: "";
}

.hidden {
  display: none;
}

/*# sourceMappingURL=index.130a7c4a.css.map */
